/* src/Connexion.css */
.login-body {
    background-color: #f0f0f5;
    display: flex;
    justify-content: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    margin-top: 100px;
    background: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* text-align: center; */
    width: 280px;
    height: 370px;
  }

  .login-container h2 {
    font-family: 'RecoletaAltBold';
    align-items: left;
  }
  
  .title-login {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-family: 'RecoletaAltBold';
    display: flex;
    justify-content: center;
  }
  
  .input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .button {
    width: 100%;
    padding: 12px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #5754d1;
  }
  
  .link {
    display: block;
    margin-top: 1rem;
    color: #6c63ff;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  