/* Header.css */
.header-acceuil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    /* background: #fff; Assuming a white background */
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); For a subtle shadow */
  }
  
  .sidebar-mobile a {
    font-family: 'Lato-Bold';
  }

  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-logo {
    font-weight: bold;
    font-size: 24px;
    /* Add additional styles for the logo */
  }
  
  .nav-links a,
  .nav-actions a {
    margin: 0 10px;
    text-decoration: none;
    color: #333333; /* A darker text color for better readability */
    /* Add additional styles for the links */
  }
  
  .nav-actions .sign-in {
    background: none;
    border: none;
    /* Styles for the 'Sign In' text link */
  }
  
  .nav-actions .start {
    background-color: #636C8F; /* Example blue background */
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    /* Additional styles for the 'Start' button */
  }

  .nav-actions .sign-in {
    background-color: #fff; /* Example blue background */
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    /* Additional styles for the 'Start' button */
  }
  
  /* Add responsive design or other specific styles as needed */
  
  .header {
    position: relative;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .menu-icon {
    display: none; /* Cache le menu burger sur desktop */
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Pleine largeur en mobile */
  }
  
  .sidebar a {
    padding: 10px;
    text-decoration: none;
    color: black;
    font-size: 18px;
  }
  
  /* Styles spécifiques pour mobile */
  @media (max-width: 768px) {
    .menu-icon {
      display: block; /* Affiche le menu burger sur mobile */
    }
  
    .sidebar {
      position: fixed;
      top: 0;
      left: -100%;
      height: 100vh;
      background-color: white;
      transition: left 0.3s;
      z-index: 1000;
    }
  
    .sidebar.open {
      left: 0; /* Fait apparaître la sidebar */
    }
  }
  
  /* Styles spécifiques pour desktop */
  @media (min-width: 769px) {
    .navbar {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  
    .sidebar {
      flex-direction: row;
      align-items: center;
      position: static;
      background-color: transparent; /* Supprime le fond pour le mode desktop */
      height: auto;
      width: auto;
      left: auto;
      transition: none;
    }
  
    .sidebar a {
      padding: 0 10px; /* Espacement horizontal entre les liens */
    }
  
    .nav-actions {
      display: flex;
    }
  }

  @media (max-width: 430px) {
    .sidebar-mobile {
      display: flex;
      flex-direction: column;
    }
  }
  