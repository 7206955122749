/* src/PasswordReset.css */
.password-reset {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

.password-reset h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'RecoletaAltBold';
}

.password-reset form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.password-reset label {
    font-size: 16px;
    text-align: left;
    font-family: 'RecoletaAltBold';
}

.password-reset input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.password-reset button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #5a6782;
    color: #fff;
    cursor: pointer;
}

.password-reset button:hover {
    background-color: #4a5672;
}
