/* src/SubscriptionManagement.css */
.subscription-management {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
}

.section {
    margin-bottom: 20px;
}

h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.cancel-button, .change-plan-button, .remove-button, .add-card-button, .other-payment-methods-button {
    background-color: #fff;
    color: #ff5e00;
    border: 1px solid #ff5e00;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
}

.change-plan-button {
    background-color: #5a6782;
    color: white;
    border: none;
    margin-top: 10px;
}

.payment-method {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.card-info {
    display: flex;
    align-items: center;
}

.card-icon {
    margin-right: 10px;
}
