@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

.testimonialsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #F8F4F2;
    margin: auto;
    max-width: 1200px;
  }
  
  .testimonialsContainer h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-family: 'RecoletaAltBold';
  }
  
  .testimonialsContent {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    gap: 20px;
    margin-top: 20px;
  }
  
  .navButton {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    border: 0.5px solid black;
  }
  
  .testimonial {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    max-width: 600px;
    width: 100%;
  }
  
  .testimonialText {
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .testimonialAuthor {
    display: flex;
    align-items: center;
  }
  
  .authorImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .testimonialAuthor div {
    display: flex;
    flex-direction: column;
  }
  
  .testimonialAuthor strong {
    font-size: 0.9rem;
  }
  
  .testimonialAuthor p {
    font-size: 0.8rem;
    color: #666;
  }
  