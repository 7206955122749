.contact-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f8f6;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 20px auto;
}

.contact-form {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.contact-form textarea {
    height: 100px;
    resize: none;
}

.file-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.file-upload input[type="file"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.file-upload-text {
    font-size: 16px;
    color: #5a6782;
}

.file-info {
    font-size: 12px;
    color: #888;
    margin-top: -15px;
    margin-bottom: 20px;
}

.contact-form button {
    width: 100%;
    padding: 15px;
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #3e4a61;
}

.contact-note {
    font-size: 14px;
    color: #555;
    margin-top: 20px;
    text-align: center;
}

.contact-note strong {
    color: #5a6782;
}
