/* src/AccountInfo.css */
.account-info {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
}

.profile-image-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.image-instructions {
    flex: 1;
}

.change-button {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
}

.personal-info h2, .pronouns h2, .interests h2, .notifications h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    flex-direction: column;
}

.info-item label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.info-item input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f5f5f5;
    font-size: 14px;
    color: #555;
}

.pronoun-options, .interest-options, .notification-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.pronoun-button, .interest-button, .notification-button {
    background-color: #f5f5f5;
    border: 1px solid #5a6782;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    color: #5a6782;
}

.pronoun-button:hover, .interest-button:hover, .notification-button:hover {
    background-color: #5a6782;
    color: white;
}

.save-button {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
}
