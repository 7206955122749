/* src/SlotCalendarComponent.css */
.ui-section {
    padding: 20px;
}

.header-slot {
    display: flex;
    align-items: center;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    margin-right: 10px;
}

.slot-button {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
}

.content .menu-item {
    margin-top: 10px;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.modal-content {
    margin-top: 10px;
}

.format-options button,
.duration-options button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #5a6782;
    color: white;
    cursor: pointer;
}

.date-time-selection input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
}

.session-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.session-detail,
.session-info,
.session-when,
.session-address,
.session-organizer,
.session-fee {
    flex: 1;
}

.cancel-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
}

.voir-plus {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
}
