@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

.howItWorksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px auto;
    max-width: 1200px;
  }
  
  .howItWorksImageWrapper {
    flex-basis: 40%;
  }
  
  .howItWorksImage {
    width: 100%;
    border-radius: 15px; /* Adjust to match the image's border-radius */
  }
  
  .howItWorksContent {
    flex-basis: 60%;
    text-align: left;
  }
  
  .howItWorksTitle {
    font-size: 2rem;
    margin-bottom: 30px;
    font-family: 'RecoletaAltBold';
  }
  
  .howItWorksSteps {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .howItWorksStep {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-right: 20px; */
  }

  .howItWorksStep img {
    /* display: flex;
    align-items: center;
    gap: 10px; */
    margin-right: 30px;
  }
  
  .stepNumber {
    font-size: 1.5rem;
    margin-right: 10px;
    min-width: 20px;
  }
  
  .stepDescription {
    font-size: 1rem;
  }
  