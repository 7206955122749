.faq-section {
    background-color: #5a6782;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 800px;
    color: white;
    text-align: center;
}

.faq-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.faq-header p {
    font-size: 16px;
    margin: 0;
}
