/* src/MiniCalendar.css */
.mini-calendar {
    width: 250px;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #ffffff;
}

.calendar-header-smile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.month-year {
    font-size: 20px;
    font-weight: bold;
}

.navigation button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.calendar-body {
    display: flex;
    flex-direction: column;
}

.days-of-week {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.day-header {
    width: 14%;
    text-align: center;
    font-weight: bold;
    color: #888;
}

.days-grid {
    display: flex;
    flex-wrap: wrap;
}

.day-cell {
    width: 14%;
    text-align: center;
    margin-bottom: 10px;
}

.day-cell.empty {
    background-color: #f9f9f9;
}

.highlight {
    color: #e74c3c;
    font-weight: bold;
}
