.psychologist-profile {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.profile-image {
    margin-right: 20px;
    text-align: center;
}

.profile-image img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.appointment-button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #6c7ae0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.profile-details {
    max-width: 500px;
}

.profile-details h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}

.job-title, .location {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
}

.about, .experience, .specialties {
    margin-top: 15px;
}

.about h4, .experience h4, .specialties h4 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
}

.specialty-tags {
    display: flex;
    gap: 10px;
}

.tag {
    padding: 5px 10px;
    background-color: #eef;
    border-radius: 5px;
    font-size: 14px;
}
