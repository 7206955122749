/* src/MoodOverview.css */
.mood-overview {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

.mood-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 50%;
}

.mood-parents {
    width: 100%;
    display: flex;
}

.mood-bar {
    width: 10px;
    border-radius: 5px;
}

.happy {
    width: 100px;
    height: 10px;
    background-color: #ff6f61;
}

.satisfied {
    width: 80px;
    height: 10px;
    background-color: #ffdd57;
}

.neutral {
    width: 60px;
    height: 10px;
    background-color: #769fcd;
}

.sad {
    width: 40px;
    height: 10px;
    background-color: #76c7c0;
}

.distressed {
    width: 30px;
    height: 10px;
    background-color: #ccc;
}

.mood-legend {
    text-align: left;
    margin-bottom: 20px;
    width: 50%;
}

.mood-legend p {
    margin: 5px 0;
    font-size: 14px;
}

.mood-legend .happy-circle,
.mood-legend .satisfied-circle,
.mood-legend .neutral-circle,
.mood-legend .sad-circle,
.mood-legend .distressed-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

/* .happy-circle {
    background-color: #ffdd57;
}

.satisfied-circle {
    background-color: #76c7c0;
}

.neutral-circle {
    background-color: #ccc;
}

.sad-circle {
    background-color: #769fcd;
}

.distressed-circle {
    background-color: #ff6f61;
} */

.mood-legend-flex {
    display: flex;
    align-items: center;
    padding: 5px;
}

.mood-legend-flex img {
    margin-right: 10px;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.mood-summary {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.mood-details {
    font-size: 14px;
}
