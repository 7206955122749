/* src/RoleSelection.css */
.body-role {
    background-color: #f0f0f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container-role {
    text-align: center;
    padding: 20px;
  }
  
  .title-role {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'RecoletaAltBold';
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .card {
    background: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    text-decoration: none;
    color: #000;
  }

  .card img {
    margin: 20px 0px;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-title {
    font-size: 1.2rem;
    margin-top: 1rem;
    font-family: KalamBold;
  }

  .card-para {
    font-family: KalamBold;
  }
  