@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

/* src/PromoSection.css */
.promo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  /* background-color: #fff; */
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.promo-text {
  flex: 1;
  margin-right: 20px;
}

.promo-text h1 {
  font-size: 36px;
  margin-bottom: 10px;
  font-family: 'RecoletaAltBold';
}

.promo-text h1 span {
  color: #ff5e00;
}

.promo-text h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'RecoletaAltBold';
}

.promo-text p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
  font-family: 'ModernEraRegular';
}

.promo-buttons {
  display: flex;
  align-items: center;
  font-family: 'ModernEraRegular';
}

.start-button, .learn-more-button {
  background-color: #5a6782;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  font-family: 'ModernEraRegular';
}

.learn-more-button {
  background-color: transparent;
  color: #5a6782;
  border: 1px solid #5a6782;
}

.promo-image {
  flex: 1;
}

.promo-image img {
  width: 100%;
  border-radius: 10px;
}