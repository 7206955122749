/* src/AccountBanner.css */
.account-banner {
    background-color: #5a6782;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
}

.banner-title {
    font-size: 20px;
    font-weight: bold;
}
