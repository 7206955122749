.abonnement-header {
    background-color: #6c7ae0;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
}
