/* src/ThankYouModal-component.css */
.modal-overlay-thankyou {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content-thankyou {
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 400px;
    text-align: center;
  }
  
  .thankyou-message {
    font-size: 1rem;
    margin: 20px 0;
  }
  
  .continue-button {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .continue-button:hover {
    background-color: #5754d1;
  }
  