@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

/* src/FAQSection.css */
.faq-section-home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.faq-image {
  flex: 1;
  margin-right: 20px;
}

.faq-image img {
  width: 100%;
  border-radius: 10px;
}

.faq-content {
  flex: 2;
}

.faq-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'RecoletaAltBold';
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.faq-answer {
  padding: 10px 20px;
  font-size: 14px;
  color: #555;
}
