/* src/FilterBar.css */
.filter-bar {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    margin: 20px;
}

.location-search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.location-button {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.edit-icon {
    margin-left: 5px;
}

.search-box {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 10px;
    flex: 1;
}

.search-icon {
    font-size: 16px;
    margin-right: 10px;
    color: #888;
}

.search-box input {
    border: none;
    outline: none;
    width: 100%;
    background: none;
    padding: 5px;
    font-size: 14px;
}

.filter-options {
    display: flex;
    justify-content: space-between;
}

.filter-select {
    background-color: #f5f5f5;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    flex: 1;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
