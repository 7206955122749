.eventRegister-parent {
  max-width: 1200px;
  margin: auto;
}

.eventCard {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
  }
  
  .eventImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
  }
  
  .eventDetails {
    flex-grow: 1;
  }
  
  .eventDate {
    font-size: 14px;
    font-weight: bold;
  }
  
  .eventTitle {
    font-size: 16px;
    color: #333;
  }
  
  .eventDescription {
    font-size: 12px;
    color: #666;
    margin: 5px 0;
  }
  
  .eventLocation {
    font-size: 12px;
    color: #333;
  }

  .DateIcon-Register {
    display: flex;
  }

  .eventCard-parent {
    display: flex;
  }

  .eventTitle-parent {
    display: flex;
  }

  .eventTitle-parent h1 {
    font-family: 'RecoletaAltBold';
  }

  .eventTitle-parent a {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }