.crisis-resources-section {
    background-color: #5a6782;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 800px;
    color: white;
    text-align: center;
}

.crisis-resources-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.crisis-resources-header p {
    font-size: 16px;
    margin: 0;
}

.crisis-resources-content {
    text-align: left;
    color: black;
    background: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.crisis-resources-content p {
    font-size: 16px;
    margin-bottom: 10px;
}

.crisis-resources-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.crisis-resources-content ul {
    list-style-type: none;
    padding: 0;
}

.crisis-resources-content li {
    font-size: 16px;
    margin-bottom: 5px;
}

.crisis-note {
    font-size: 14px;
    margin-top: 20px;
    color: gray;
}
