/* src/UserCard.css */
.user-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.user-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-tag {
    background-color: #f0f0f0;
    color: #000;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 10px;
}

.user-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.user-details, .user-location, .user-hobby {
    font-size: 14px;
    margin: 3px 0;
}

.user-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.user-button {
    background-color: #5a6782;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
}

.user-button.contact {
    background-color: #ffffff;
    color: #5a6782;
    border: 1px solid #5a6782;
}
