/* src/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* background-color: #fff; */
  /* border-bottom: 1px solid #ddd; */
  font-family: Arial, sans-serif;
  width: 100%;
  margin-bottom: 20px;
  /* position: fixed; */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.icons {
  display: flex;
  align-items: center;
}

.icon, .profile {
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.dropdown-body {
  padding: 10px;
}

.notification-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.notification-item p {
  margin: 5px 0;
}

.notification-item button {
  background-color: transparent;
  border: 1px solid #ff5e00;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  color: #ff5e00;
  cursor: pointer;
}

.help p, .profile p {
  margin: 10px 0;
  cursor: pointer;
}
