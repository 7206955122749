@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

.socialCalendarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .socialCalendarHeader h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: 'RecoletaAltBold';
  }
  
  .socialCalendarHeader p {
    font-size: 1rem;
    color: grey;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .locationFilter {
    display: flex;
    margin-bottom: 2rem;
  }
  
  .locationFilter input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    margin-right: -1px; /* Align with the button */
  }
  
  .locationFilter button {
    padding: 10px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .eventsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 80%;
  }
  
  .eventCard {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .eventImage {
    width: 100%;
    display: block;
  }
  
  .eventDetails {
    padding: 10px;
  }
  
  .eventDate,
  .eventLocation,
  .eventAvailability {
    font-size: 0.9rem;
    color: grey;
    margin-bottom: 5px;
  }
  
  .eventTitle {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .eventDescription {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .navigationButtons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  
  .prevButton,
  .nextButton {
    border: 0.5px solid black;
    border-radius: 5px;
    padding: 8px 10px;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .prevButton:hover,
  .nextButton:hover {
    background: #636C8F;
    color: #fff;
  }
  
  .dateCS, .date2CS {
    display: flex;
    align-items: center;
  }