/* src/BlogPostDetail.css */
.blog-post-detail {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
}

.breadcrumb {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.post-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.post-meta {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.post-author-avatar {
    border-radius: 50%;
    margin-right: 10px;
}

.post-author-info {
    display: flex;
    flex-direction: column;
}

.post-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.post-content {
    line-height: 1.6;
}

.post-content p {
    margin-bottom: 10px;
}

.post-content ol {
    padding-left: 20px;
}

.post-content li {
    margin-bottom: 10px;
}
