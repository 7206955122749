.faq-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.faq-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.faq-header p {
    font-size: 16px;
    color: #555;
}

.faq-content {
    margin-top: 20px;
}

.faq-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.faq-question {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
}

.faq-contact {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #777;
}

.faq-contact a {
    color: #007bff;
    text-decoration: none;
}

.faq-contact a:hover {
    text-decoration: underline;
}
