/* src/ResultsForm-component.css */
.body-results-form {
    background-color: #f0f0f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container-results-form {
    background: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: left;
    width: 600px;
  }
  
  .title-results-form {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .content-section {
    margin-bottom: 20px;
  }
  
  .heading {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
  }
  
  .subscription-section-parent {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .subscription-section {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
  }
  
  .plan-card {
    width: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }
  
  .plan-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 2rem;
    color: #6c63ff;
    margin-bottom: 10px;
  }
  
  .plan-features {
    font-size: 0.9rem;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .plan-feature {
    margin-bottom: 5px;
  }
  
  .button-select {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-select:hover {
    background-color: #5754d1;
  }  