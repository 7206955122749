@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

/* WhoWeHelp.css */
.who-we-help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px; /* Adjust padding to fit your design */
    background-color: #F8F4F2; /* Replace with actual color if necessary */
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .content {
    max-width: 50%;
  }

  .title-wwh h2 {
    font-family: 'RecoletaAltBold';
  }
  
  .title-wwh {
    text-align: center;
  }
  
  .intro,
  .description {
    margin-bottom: 20px;
  }
  
  .start-button2 {
    background-color: #636C8F; /* Example button color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px; /* Adjust font size as needed */
    cursor: pointer;
    border-radius: 5px; /* Adjust as needed for design */
    text-transform: uppercase; /* Example text style */
  }
  
  .image-wrapper {
    max-width: 40%;
    /* Add any additional styling for the image wrapper */
  }
  
  .person-image {
    width: 100%;
    height: auto;
    /* Adjust size as needed, possibly using object-fit for aspect ratios */
  }
  
  @media (min-width: 430px) {
    .who-we-help {

    }
  }
    