.body-diary {
    width: 100%;
    /* margin-top: 4em;
    margin-left: 17em; */
}

.ReqStat {
    display: flex;
    max-width: 1200px;
    margin: auto;
}