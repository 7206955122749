/* src/EventsRecommended.css */
.events-recommended {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    font-family: Arial, sans-serif;
}

.events-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.events-header h2 {
    font-size: 24px;
    margin: 0;
    font-family: 'RecoletaAltBold';
}

.view-all {
    background-color: transparent;
    border: 1px solid #ff5e00;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
    color: #ff5e00;
    cursor: pointer;
}

.event-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.event-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.event-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.event-details {
    padding: 10px;
}

.event-date, .event-title, .event-description, .event-location {
    margin: 5px 0;
}

.event-date {
    font-size: 14px;
    color: #888;
}

.event-title {
    font-size: 16px;
    font-weight: bold;
}

.event-description {
    font-size: 14px;
    color: #555;
}

.event-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.event-location {
    font-size: 14px;
    color: #888;
}

.event-person {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
