@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

/* Statistics.css */
.statistics-section {
    display: flex;
    justify-content: space-around; /* Adjust spacing as needed */
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0; /* Adjust padding as needed */
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .statistic {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .statistic-number {
    color: #636C8F; /* Replace with the actual color from the design */
    font-size: 3rem; /* Adjust font size as needed */
    font-weight: bold;
    font-family: 'RecoletaAltBold';
  }
  
  .statistic-description {
    font-size: 1.5rem; /* Adjust font size as needed */
  }

  .statistic-parent {
    display: flex;
    flex-wrap: wrap;
  }

  .statistic-plus {
    font-family: 'ModernEraBold';
  }
  
  /* Add any additional global or specific styles as needed */
  