.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content ul {
    list-style: none;
    padding: 0;
  }
  
  .modal-content ul li {
    margin-bottom: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-footer button:first-child {
    background: #ccc;
  }
  
  .modal-footer button:last-child {
    background: #6c7ae0;
    color: white;
  }
  