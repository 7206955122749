@font-face {
  font-family: 'RecoletaAltBold';
  src: url('../../../fonts/Recoleta\ Alt\ Bold.otf') format('opentype');
}

@font-face {
  font-family: 'ModernEraRegular';
  src: url('../../../fonts/ModernEra-Regular.ttf') format('truetype');
}

.volunteerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 20px;
    text-align: center;
    max-width: 1200px;
  }
  
  .volunteerHeader {
    margin-bottom: 30px;
  }
  
  .volunteerTitle {
    margin-bottom: 20px;
    font-size: 2em; /* Example size */
    font-family: 'RecoletaAltBold';
  }
  
  .volunteerSubtitle {
    margin-bottom: 20px;
    font-size: 1em; /* Example size */
  }

  .volunteerList-parent {
    display: flex;
    /* flex-wrap: wrap; */
  }
  
  .volunteerList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between items */
    width: 100%;
  }
  
  .volunteerItem {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between image and text */
  }
  
  .imageWrapper {
    overflow: hidden;
    margin-right: 30px;
  }
  
  .volunteerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .volunteerContent {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  
  .startButton {
    margin-top: 30px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #636C8F; /* Example color */
    color: white;
    font-size: 1em; /* Example size */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .startButton:hover {
    background-color: #003d82; /* Darker blue on hover */
  }
  