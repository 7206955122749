/* src/RecommendedArticles.css */
.recommended-articles {
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.recommended-articles h1 {
    font-family: 'RecoletaAltBold';
}

h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.voir-tout {
    font-size: 14px;
    font-weight: normal;
    color: #888;
    margin-left: 10px;
}

.articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.article-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    max-width: 200px;
}

.article-image {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.article-details {
    display: flex;
    flex-direction: column;
}

.article-category {
    font-size: 14px;
    color: #ff5e00;
    border: 1px solid #ff5e00;
    padding: 2px 5px;
    border-radius: 5px;
    width: fit-content;
    margin-bottom: 10px;
}

.article-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px;
}

.article-author {
    font-size: 14px;
    color: #555;
    display: flex;
    justify-content: space-between;
}
