/* src/WelcomeBanner.css */
.welcome-banner {
  background-color: #5a6782;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
}

.welcome-text {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  font-family: 'RecoletaAltBold';
}

.welcome-subtext {
  font-size: 14px;
}
