/* src/Finish-Form-component.css */
.body-finish-form {
    background-color: #f0f0f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container-finish-form {
    background: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    width: 400px;
  }
  
  .title-finish-form {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .step-indicator {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1rem;
  }
  
  .step {
    background: #d3d3d3;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  
  .step.active {
    background: #6c63ff;
    color: white;
  }
  
  .form-group-finish {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .label-finish {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .input-finish {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .checkbox-finish {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .checkbox-finish input {
    margin-right: 0.5rem;
  }
  
  .button-finish {
    width: 100%;
    padding: 12px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .button-finish:hover {
    background-color: #5754d1;
  }
  