/* src/Calendar.css */
.calendar {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    width: 80%;
    margin: 0 auto;
}

.calendar-header-calendar, .calendar-row {
    display: flex;
}

.calendar-header-calendar {
    background-color: #FFE5E0;
}

.time-header {
    width: 80px;
    padding: 10px;
    text-align: right;
    /* background-color: #f8f9fa; */
    border: 1px solid #ddd;
    background-color: #FFE5E0; 
}

.time-cell {
    width: 80px;
    padding: 10px;
    text-align: right;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
}

.day-header-right-calendar {
    flex: 1;
    padding: 10px;
    text-align: center;
    /* border: 1px solid #ddd; */
    position: relative;
}

.day-cell-right-calendar {
    flex: 1;
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    position: relative;
}

.day-cell {
    background-color: #fff;
}

.date {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    color: #888;
}

.event {
    margin-top: 20px;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
}
